
  import { defineComponent, ref, watch } from 'vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { Form, Field } from 'vee-validate';
  import { useI18n } from 'vue-i18n/index';
  import { useRouter } from 'vue-router';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';

  interface IHeaderConfiguration {
    name?: string;
    key: string;
    sortingField?: string;
    sortable?: boolean;
  }
  interface IreFactorValueObject {
    id: number;
    sizeId: number;
    flooringId: number;
    propertyTypeId: number;
    descriptionEn: string;
    descriptionAr: string;
    propertySize?: string;
    title: string;
  }

  export default defineComponent({
    name: 'ire-unit-comp-values-records',
    emit: ['list-unit-comp-val-ire', 'submited'],
    props: {
      cardClasses: String,
      tableHeader: {
        type: Array as () => Array<IHeaderConfiguration>,
        required: true,
      },
      tableData: {
        type: Array as () => Array<IreFactorValueObject>,
        required: true,
      },
      loading: { type: Boolean, required: false },
      areaId: { type: Number, required: true },
    },

    components: {
      Datatable,
      Form,
      Field,
    },
    setup(props, { emit }) {
      const { t, te } = useI18n();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const router = useRouter();
      const { can } = useAbility();
      let test: any = ref(null);

      watch(
        () => props.tableData,
        (changed) => {
          emit('list-unit-comp-val-ire', changed);
        },
        { deep: true }
      );

      const changeTableData = async (val) => {
        console.log(val);
        props.tableData.map((data) => {
          if (data.id) {
            if (data.id === val.id) {
              Object.assign(data, val);
            }
          } else if (
            data.sizeId === val.sizeId &&
            data.flooringId === val.flooringId &&
            data.propertyTypeId === val.propertyTypeId
          ) {
            // data. = val;
            Object.assign(data, val);
          }
        });
      };

      // const changeTableData = async (val) => {
      //   test = val;
      // updatedData = []
      // props.tableData.map((data) => {
      //   if (data.sizeId === val.sizeId && data.flooringId === val.flooringId) {
      //     data.propertySize = val.propertySize === '' ? null : Number(val.propertySize);
      //     updatedData.push(data)
      //   }
      // });
      // props.tableData = val;
      // props.tableData.map((data) => {
      //   if (data.sizeId === val.sizeId && data.flooringId === val.flooringId) {
      //     data.propertySize = val.propertySize === '' ? null : Number(val.propertySize);
      //   }
      // });
      // };
      const goBack = () => {
        router.back();
      };

      const propertyTypeSchema = Yup.object().shape({
        propertySize: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        descriptionAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
      });

      const onSubmitUpdate = () => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        emit('submited', props.tableData);
        //Deactivate indicator
        // submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        // submitButton.value!.disabled = false;
      };

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      return {
        propertyTypeSchema,
        translate,
        changeTableData,
        onSubmitUpdate,
        submitButton,
        goBack,
        can,
        test,
      };
    },
  });
